import { useRoute } from 'vue-router';
import { useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { useApi } from './api';
import { useAuditStore } from './audit';

export const VERSION = import.meta.env.VITE_BUILD_VERSION || '';

export const getMatrix = (lodgementType = '') => {
  const app = useAppStore();
  const type = lodgementType.toUpperCase();
  return app.appData?.matrixTypes?.find((matrix) => matrix.type === type);
};

export const useAppStore = defineStore({
  id: 'app',
  state: () => ({
    version: VERSION,
    appData: null,
    error: null,
    notifications: [],
    sidebarExpanded: false,
    sidebarMinimized: useLocalStorage('sidebar-minimized', false),
  }),
  getters: {
    drawerExpanded: () => {
      const route = useRoute();
      const audit = useAuditStore();
      return route.name === 'compliance-question-set' || audit.active;
    },
    hasAuditPermission: (state) => !!state.appData?.flags?.isTrustmark,
    isRC: (state) => !!state.appData?.flags?.isRetrofitCoordinator,
    isRA: (state) => !!state.appData?.flags?.isRetroAssessor,
    isTM: (state) => !!state.appData?.flags?.isTrustmark,
    isSchemeInstaller: (state) => !!state.appData?.flags?.isSchemeInstaller,
    isSchemeRCRA: (state) => !!state.appData?.flags?.isSchemeRCRA,
  },
  actions: {
    async init() {
      const api = useApi();
      this.appData = await api.silently().throwOnError().getAppData();
    },
    addNotification(notification) {
      // Create a simple ID if not provided
      if (!notification.id) notification.id = new Date().valueOf();
      this.notifications.push(notification);
      if (notification.timeout) {
        setTimeout(() => this.removeNotification(notification.id), notification.timeout);
      }
      return notification.id;
    },
    removeNotification(id) {
      const index = this.notifications.findIndex((n) => n.id === id);
      if (index > -1) {
        this.notifications.splice(index, 1);
      }
    },
  },
});
