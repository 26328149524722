<template>
  <div v-if="audit.onsite" class="d-flex flex-column">
    <div class="audit-section">
      <FormWrapper label="">
        <h5 class="mb-2">Onsite Audit - {{ project.projectReference }}</h5>
        <div class="d-flex flex-column gap-2">
          <router-link
            v-if="project.remediationStatus"
            :to="link"
            class="result-label btn btn-primary text-white"
            >Go to Remediation</router-link
          >
        </div>
      </FormWrapper>
    </div>

    <div class="audit-section d-flex flex-column gap-3">
      <FormInput
        v-if="audit.onsite.lastAppointmentDateTime"
        label="Last Appointment"
        id="lastAppointmentDateTime"
        class="w-100"
        :model-value="
          formatISOStringDate(
            audit.onsite.lastAppointmentDateTime,
            'EEE do MMM yyyy \'at\' hh:mmaaa'
          )
        "
        :popover="bookingHistory"
        disabled
      />
      <FormInput
        v-if="audit.onsite.currentAppointmentDateTime"
        label="Next Visit On"
        id="currentAppointmentDateTime"
        class="w-100"
        :model-value="
          formatISOStringDate(
            audit.onsite.currentAppointmentDateTime,
            'EEE do MMM yyyy \'at\' hh:mmaaa'
          )
        "
        disabled
      />
    </div>

    <div class="audit-section" :class="{ 'order-3': isComplete }">
      <FormWrapper label="Actions">
        <div class="d-flex flex-column gap-2">
          <Popover v-for="survey in audit.onsite.surveyReports" :key="survey.tmln" :content="survey.businessName" trigger="hover">
            <button
              class="btn btn-primary w-100"
              type="button"
              @click.prevent="onDownloadSurveyReport(survey)"
            >
              View {{ survey.reportType || 'Installer Report' }} ({{ survey.tmln }})
            </button>
          </Popover>
        </div>
      </FormWrapper>
    </div>

    <div v-if="forms.length" class="audit-section" :class="{ 'order-2': isComplete }">
      <FormWrapper label="Forms">
        <div class="d-flex flex-column gap-2">
          <AuditOnsiteFormThumb
            v-for="form of forms"
            :key="form.auditFormId"
            v-bind="form"
            @select="emit('form', form)"
          />
        </div>
      </FormWrapper>
    </div>
    <div
      v-if="appointmentBooked || isComplete || inReview"
      class="audit-section"
      :class="{ 'order-1': isComplete }"
    >
      <FormWrapper label="Outcomes">
        <div class="d-flex flex-column gap-2">
          <div v-for="outcome of outcomes" :key="outcome.title" class="result">
            <div class="result-label">{{ outcome.title }}</div>
            <StatusBadge
              :status="outcome.nonComplianceSeverity || outcome.outcome || undefined"
              :show-popover-icon="outcome.incompleteReason"
              :popover="outcome.incompleteReason"
              small
            />
          </div>
        </div>
      </FormWrapper>
    </div>

    <div
      v-if="
        (isComplete || inReview) &&
        (audit.onsite.observations || audit.onsite.internalNotes)
      "
      class="audit-section"
      :class="{ 'order-4': isComplete }"
    >
      <div class="d-flex flex-column gap-3">
        <FormTextarea
          v-if="audit.onsite.observations"
          id="notes"
          label="Observations"
          rows="3"
          :model-value="audit.onsite.observations"
          disabled
        />
        <FormTextarea
          v-if="audit.onsite.internalNotes"
          id="notes"
          label="Internal Notes"
          rows="3"
          :model-value="audit.onsite.internalNotes"
          disabled
        />
      </div>
    </div>

    <Transition name="swipe-right">
      <div>
        <AuditOnsiteReview
          v-if="state.showingReview"
          :forms="forms"
          :current="audit.onsite"
          @confirm="onConfirmComplete"
          @dismiss="state.showingReview = false"
        />
        <AuditOnsiteNoAccess
          v-else-if="state.showingNoAccessFlag"
          :audit="audit.onsite"
          @confirm="onConfirmNoAccess"
          @dismiss="state.showingNoAccessFlag = false"
        />
        <AuditOnsiteAppointment
          v-else-if="state.showingAppointment"
          :datetime="audit.onsite.currentAppointmentDateTime"
          @confirm="onConfirmAppointment"
          @dismiss="state.showingAppointment = false"
        />
        <AuditOnsiteNonComplianceAnswers
          v-else-if="state.showingNonComplianceAnswers"
          :measures="audit.onsite.onsiteAuditMeasures"
          :forms="forms"
          @dismiss="state.showingNonComplianceAnswers = false"
        />
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  // For use with KeepAlive
  name: 'AuditOnsiteOverview',
};
</script>

<script setup>
import { computed, reactive, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useAppStore } from '../../stores/app';
import { useAuditStore } from '../../stores/audit';
import { formatISOStringDate } from '../../utilities/date';
import AuditOnsiteFormThumb from '../AuditOnsiteFormThumb.vue';
import AuditOnsiteAppointment from '../AuditOnsiteAppointment.vue';
import AuditOnsiteNoAccess from '../AuditOnsiteNoAccess.vue';
import AuditOnsiteReview from '../AuditOnsiteReview.vue';
import AuditOnsiteNonComplianceAnswers from '../AuditOnsiteNonComplianceAnswers.vue';
import FormWrapper from '../FormWrapper.vue';
import FormInput from '../FormInput.vue';
import StatusBadge from '../StatusBadge.vue';
import FormTextarea from '../FormTextarea.vue';
import { useApi } from '../../stores/api';
import { useAuthStore } from '../../stores/auth';
import { getTimezoneOffset, zonedTimeToUtc } from 'date-fns-tz';
import { isLodgementWorkStyle } from '../../stores/lodgements';
import Popover from '../Popover.vue';

const emit = defineEmits([
  'complete',
  'update',
  'form',
  'addForm',
  'reviewComplete',
  'adminMoveToReview',
  'clearAudit',
]);

const props = defineProps({
  project: {
    required: true,
    type: Object,
  },
  lodgement: {
    type: Object,
    default: () => ({}),
  },
});

const appStore = useAppStore();
const { isSchemeInstaller } = storeToRefs(appStore);
const { isDESNZViewer, isOfgemRetrofitDataViewer } = storeToRefs(useAuthStore());
const audit = useAuditStore();
const api = useApi();


const outcomes = computed(() => {
  if (!audit.onsite) return [];
  const arr = [];
  if (!isComplete.value) {
    arr.push({
      title: 'Onsite Audit',
      outcome: audit.onsite.onsiteOutcome,
      nonComplianceSeverity: audit.onsite.nonComplianceSeverity,
    });
  }
  audit.onsite.onsiteAuditMeasures.forEach((measure) => {
    const form = audit.onsite.forms.find((x) => x.measureId === measure.measureId);

    arr.push({
      title: measure.measureType,
      outcome: measure.outcome,
      nonComplianceSeverity: measure.nonComplianceSeverity,
      incompleteReason: form?.hasIncompleteReason ? form.incompleteReasons.join('<br />') : null,
    });
  });
  if (audit.onsite.onsiteAuditOtherOutcomes) {
    audit.onsite.onsiteAuditOtherOutcomes.forEach((otherOutcome) => {
      const form = audit.onsite.forms.find((x) => x.formOutcomeType === otherOutcome.outcomeType);

      arr.push({
        title: otherOutcome.outcomeType,
        outcome: otherOutcome.outcome,
        nonComplianceSeverity: otherOutcome.nonComplianceSeverity,
        incompleteReason: form?.hasIncompleteReason ? form.incompleteReasons.join('<br />') : null,
      });
    });
  }
  if (!isSchemeInstaller.value) {
    arr.push({
      title: 'H&S Risk Assessment',
      outcome: audit.onsite.healthSafetyRiskAssessmentOutcome,
    });
  }
  return arr;
});

const state = reactive({
  showingAppointment: false,
  showingNoAccessFlag: false,
  showingNonComplianceAnswers: false,
  showingReview: false,
  showingAddForm: false,
});

const appointmentRequired = computed(() => audit.onsite.status === 'Required');
const appointmentBooked = computed(() => audit.onsite.status === 'Booked');
const isComplete = computed(() => audit.onsite.status === 'Complete');
const inReview = computed(() => audit.onsite.status === 'Review');
// const noAccess = computed(() => audit.onsite.status === 'NoAccess');

const forms = computed(() => {
  const isHealthAndSafetyForm = (form) => form.formStage === 'Health & Safety Risk Assessment';
  if (appointmentRequired.value) {
    return audit.onsite.forms.filter(isHealthAndSafetyForm);
  }
  return audit.onsite.forms;
});

const bookingHistoryPrefix = (customerCancelled, trustMarkCancelled) => {
  if (customerCancelled) {
    return 'Customer Cancelled';
  }
  if (trustMarkCancelled) {
    return 'TrustMark Cancelled';
  }
  return '';
};

const bookingHistory = computed(() => {
  if (isSchemeInstaller.value) {
    return (
      'Booking History:<br />' +
      audit.onsite.bookingHistory
        ?.map((x) => {
          return `${bookingHistoryPrefix(
            x.customerCancelledFlag,
            x.trustMarkCancelledFlag
          )}${formatISOStringDate(x.appointmentDateTime, "EEE do MMM yyyy 'at' hh:mmaaa")}`;
        })
        .join('<br />')
    );
  } else {
    return (
      'Booking History:<br />' +
      audit.onsite.bookingHistory
        ?.map((x) => {
          return `${bookingHistoryPrefix(
            x.customerCancelledFlag,
            x.trustMarkCancelledFlag
          )}${formatISOStringDate(x.appointmentDateTime, "EEE do MMM yyyy 'at' hh:mmaaa")} - ${
            x.auditor
          }`;
        })
        .join('<br />')
    );
  }
});

const onConfirmAppointment = ({ date, time, customerCancelled, trustMarkCancelled }) => {
  let appointmentDate = date?.replace('00:00', time) || null;
  if (appointmentDate !== null) {
    const timezone = getTimezoneOffset();
    appointmentDate = zonedTimeToUtc(appointmentDate, timezone);
  }
  emit('update', {
    updates: {
      appointmentDate,
      noAccess: false,
      customerCancelled: customerCancelled || false,
      trustMarkCancelled: trustMarkCancelled || false,
      noAccessReasons: [],
    },
    next() {
      state.showingAppointment = false;
    },
  });
};

const onConfirmNoAccess = (noAccessReasons = []) => {
  emit('update', {
    updates: { noAccess: true, noAccessReasons },
    next() {
      state.showingNoAccessFlag = false;
    },
  });
};

const onConfirmComplete = async (form) => {
  emit('complete', {
    form,
    next() {
      state.showingReview = false;
    },
  });
};

const projectVisitLogs = ref([]);

const getProjectVisitLogs = async () => {
  const result = await api.getOnsiteAuditVisitLogsByProjectId(audit.onsite.retrofitProjectId);
  if (result.isSuccess) {
    projectVisitLogs.value = result.model;
  }
};

const link = computed(() => {
  if (props.lodgement?.lodgementId && props.lodgement?.retrofitProjectId === props.project?.retrofitProjectId && isLodgementWorkStyle('standalone', props.lodgement?.lodgementType)) {
    return {
      name: 'remediation-installer',
      params: { projectId: props.project?.retrofitProjectId, lodgementId: props.lodgement?.lodgementId },
      query: { 'onsiteAudit': 'y' },
    }
  }
  return {
    name: 'project-remediation-installer',
    params: { projectId: props.project?.retrofitProjectId },
    query: { 'onsiteAudit': 'y' },
  }
});

const onDownloadSurveyReport = async (survey) => {
  const result = await api.getPresignedSurveyReportUrl(survey?.surveyReportS3Key || audit.onsite.surveyReportS3Key);
  if (result && typeof result === 'string') {
    const link = document.createElement('a');
    link.href = result;
    link.setAttribute('download', `${survey?.surveyReportS3Key || audit.onsite.surveyReportS3Key}.pdf`);
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer');
    link.setAttribute('type', 'application/pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};


onMounted(getProjectVisitLogs);
</script>

<style lang="scss" scoped>
.result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.result-label {
  font-weight: $form-label-font-weight;
  color: $gray-600;
  font-size: $form-label-font-size;
}
</style>
